<template>
  <div class="auth-header tw-flex tw-justify-between tw-items-center tw-mt-3 tw-px-3">
    <router-link
      :to="{ name: 'AuthView' }"
      class="auth-header__logo"
    >
      <picture>
        <source
          srcset="@/assets/img/logo_bg_white.svg"
          media="(max-width: 425px)"
        >
        <img
          src="@/assets/img/logo_bg_white.svg"
          alt="Logo Chronotruck"
          height="38"
        >
      </picture>
    </router-link>
    <template
      v-if="getShowAuthCtaBar"
    >
      <div
        v-if="$route.name === 'Register'"
      >
        <span
          v-text="$t('auth.labels.already_registered')"
          class="tw-text-sm tw-text-gray-600 tw-hidden lg:tw-inline"
          data-test="already-registered"
        />
        <UiButton
          :to="{
            name: 'SignIn'
          }"
          variant="info"
          size="sm"
          class="tw-ml-2"
          data-test="sign-in"
        >
          {{ $t('auth.buttons.sign_in') }}
        </UiButton>
      </div>
      <div
        v-else
      >
        <span
          v-text="$t('auth.labels.no_account_yet')"
          class="tw-text-sm tw-text-gray-600 tw-hidden lg:tw-inline"
          data-test="no-account"
        />
        <UiButton
          :to="{
            name: 'Register'
          }"
          variant="info"
          size="sm"
          class="tw-ml-2"
          data-test="sign-up"
        >
          {{ $t('auth.buttons.sign_up') }}
        </UiButton>
      </div>
    </template>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'AuthHeader',
    data () {
      return {
        isRegisterRoute: false,
        isSignInRoute: false,
        routeName: null
      }
    },
    computed: {
      ...mapGetters('ui', [
        'getShowAuthCtaBar'
      ])
    },
    watch: {
      $route: {
        handler: function (to) {
          this.isRegisterRoute = to.name === 'Register'
          this.isSignInRoute = to.name === 'SignIn'
          this.routeName = to.name
        }
      }
    }
  }
</script>

<style lang="scss" scoped>

  .auth-header {
    flex: 0 0 55px;
  }

</style>
