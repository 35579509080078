<template>
  <div class="auth-side-explanations tw-relative">
    <div
      class="auth-side-explanations__backdrop tw-absolute tw-flex tw-w-full tw-h-full tw-left-0 tw-top-0"
    >
      <explanation-carousel
        :explanations="explanations"
        class="tw-w-full tw-m-auto"
      />
    </div>
  </div>
</template>

<script>
  import { i18n } from '@/locales'

  import ExplanationCarousel from './_subs/ExplanationCarousel/index.vue'

  /**
   * @module component - AuthSideExplanations
   */
  export default {
    name: 'AuthSideExplanations',
    components: {
      ExplanationCarousel
    },
    methods: {
      /**
       * @param {Array<string>} phrases
       * @returns {string}
       */
      randomPhrase (phrases) {
        return phrases[Math.floor(Math.random() * phrases.length)]
      }
    },
    computed: {
      explanations () {
        const carrier = [
          'auth.paragraphs.explanations.carrier',
          'auth.paragraphs.explanations.carrier.mobile',
          'auth.paragraphs.explanations.carrier.proposition'
        ]
        const shipper = [
          'auth.paragraphs.explanations.shipper',
          'auth.paragraphs.explanations.shipper.digital'
        ]
        const didYouKnow = [
          'auth.paragraphs.explanations.did-you-know.reduc',
          'auth.paragraphs.explanations.did-you-know'
        ]

        return [
          {
            title: 'auth.titles.explanations.shipper',
            content: this.randomPhrase(shipper)
          },
          {
            title: 'auth.titles.explanations.carrier',
            content: this.randomPhrase(carrier)
          },
          {
            title: 'auth.titles.explanations.did-you-know',
            content: this.randomPhrase(didYouKnow)
          }
        ].map((explanation) => ({
          title: this.$t(explanation.title, i18n.locale),
          content: this.$t(explanation.content, i18n.locale)
        }))
      }
    }
  }
</script>

<style lang="scss" scoped>

  .auth-side-explanations {
    background-image: url('~@/assets/img/backgrounds/locale.jpg');
    background-size: cover;
    background-position: center;

    &__backdrop {
      background-color: rgba(black, 0.5);
    }

    @media only screen and (max-width: $breakpoint-tablet) {
      &__backdrop {
        position: relative;
      }
    }
  }

</style>
