<template>
  <div class="explanation-carousel">
    <div class="explanation-carousel__pagination">
      <button
        v-for="(explanation, k) in explanations"
        :key="k"
        :title="explanation.title"
        :class="{
          active: page === k
        }"
        class="explanation-carousel__pagination__item tw-cursor-pointer tw-appearance-none tw-border-none"
        @click="slideTo(k)"
      />
    </div>
    <Carousel
      ref="carousel"
      v-model="page"
      :per-page="1"
      :navigate-to="page"
      :loop="true"
      :pagination-enabled="false"
      class="tw-w-full"
    >
      <Slide
        v-for="(explanation, k) in explanations"
        :key="k"
      >
        <div class="explanation-carousel__slide tw-p-8 tw-font-light tw-text-white">
          <h3
            v-text="explanation.title"
            class="explanation-carousel__slide__title tw-text-lg"
          />
          <p
            v-text="explanation.content"
            class="explanation-carousel__slide__content tw-text-base"
          />
        </div>
      </Slide>
    </Carousel>
  </div>
</template>

<script>
  import { defineComponent, onBeforeUnmount, onMounted, ref } from '@vue/composition-api'

  import { Carousel, Slide } from 'vue-ctk-carousel'

  /**
   * @module component - ExplanationCarousel
   * @param {Array<string>} explanations
   */
  export default defineComponent({
    name: 'ExplanationCarousel',
    components: {
      Carousel,
      Slide
    },
    props: {
      explanations: {
        type: Array,
        required: true
      }
    },
    setup () {
      /** @type {import('@vue/composition-api').Ref<NodeJS.Timer|null>} */
      const interval = ref(null)
      const page = ref(0)
      /** @type {import('@vue/composition-api').Ref<any|null>} */
      const carousel = ref(null)

      function next () {
        if (carousel.value) {
          slideTo(carousel.value.getNextPage())
        }
      }

      function play () {
        interval.value = setInterval(() => {
          next()
        }, 5000)
      }

      function stop () {
        if (interval.value) {
          clearInterval(interval.value)
        }
      }

      /**
       * @param {number} index
       */
      function slideTo (index) {
        stop()
        play()

        if (carousel.value) {
          carousel.value.goToPage(index)
        }
      }

      onMounted(() => {
        play()
      })

      onBeforeUnmount(() => {
        stop()
      })

      return {
        carousel,
        page,
        slideTo
      }
    }
  })
</script>

<style lang="scss" scoped>

  .explanation-carousel {
    &__pagination {
      padding: 0 32px;

      &__item {
        height: 5px;
        width: 40px;
        margin-right: 1px;
        background-color: rgba(white, 0.35);
        transition: background-color 200ms ease-in-out;

        &:hover:not(.active) {
          background-color: rgba(white, 0.5);
        }

        &.active {
          background-color: white;
        }
      }
    }
  }

</style>
