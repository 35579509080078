var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"invite"},[(_vm.$wait.is('fetching invite'))?_c('CtkLoadingLayer',[_vm._v(" "+_vm._s(_vm.$t('app.labels.loading'))+" ")]):_c('div',{staticClass:"invite__content tw-px-4 md:tw-px-0 tw-flex tw-flex-col tw-flex-1 tw-overflow-y-auto tw-mb-20 md:tw-mb-0"},[_c('div',{staticClass:"invite__wrapper tw-mx-auto tw-py-4 md:tw-pt-24 md:tw-pb-12"},[_c('div',{staticClass:"tw-mb-10 tw-flex tw-flex-col"},[_c('h1',{staticClass:"invite__header-title tw-text-xl tw-font-normal tw-flex-1 tw-pb-2",domProps:{"textContent":_vm._s(_vm.$t('auth.titles.invite'))}})]),_c('ValidationObserver',{ref:"observer",attrs:{"slim":""}},[_c('form',{attrs:{"disabled":_vm.$wait.is('creating account')},on:{"submit":function($event){$event.preventDefault();return _vm.submitted.apply(null, arguments)}}},[_c('ValidationProvider',{ref:"first_name-provider",attrs:{"name":_vm.$t('app.fields.first_name'),"rules":"required|max:255","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
var errors = ref.errors;
return [_c('ctk-input-text',{staticClass:"tw-mb-4",attrs:{"id":"first_name","label":_vm.$t('app.labels.first_name'),"error":invalid && validated || errors && errors.length > 0,"hint":errors[0],"disabled":_vm.$wait.is('creating account'),"type":"text","name":"first_name","autocomplete":"given-name","required":""},model:{value:(_vm.formData.firstName),callback:function ($$v) {_vm.$set(_vm.formData, "firstName", $$v)},expression:"formData.firstName"}})]}}])}),_c('ValidationProvider',{ref:"last_name-provider",attrs:{"name":_vm.$t('app.fields.last_name'),"rules":"required|max:255","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
var errors = ref.errors;
return [_c('ctk-input-text',{staticClass:"tw-mb-4",attrs:{"id":"last_name","label":_vm.$t('app.labels.last_name'),"error":invalid && validated || errors && errors.length > 0,"hint":errors[0],"disabled":_vm.$wait.is('creating account'),"data-vv-as":_vm.$t('app.fields.last_name'),"type":"text","name":"last_name","autocomplete":"family-name","required":""},model:{value:(_vm.formData.lastName),callback:function ($$v) {_vm.$set(_vm.formData, "lastName", $$v)},expression:"formData.lastName"}})]}}])}),_c('ValidationProvider',{ref:"phone-provider",attrs:{"rules":("telephone:" + (_vm.formData.country)),"name":_vm.$t('phone'),"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
var errors = ref.errors;
return [_c('ctk-phone-number',{staticClass:"tw-mb-4",attrs:{"id":"phone","country":_vm.formData.country,"hint":errors[0],"disabled":_vm.$wait.is('creating account'),"error":invalid && validated || errors && errors.length > 0,"name":"phone","autocomplete":"tel-national"},on:{"update:country":function($event){return _vm.$set(_vm.formData, "country", $event)},"update":_vm.phoneUpdated},model:{value:(_vm.formData.phone),callback:function ($$v) {_vm.$set(_vm.formData, "phone", $$v)},expression:"formData.phone"}})]}}])}),_c('ValidationProvider',{ref:"password-provider",attrs:{"vid":"password","name":_vm.$t('app.fields.password'),"rules":"required|min:8","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
var errors = ref.errors;
return [_c('ctk-input-text',{staticClass:"tw-w-full tw-mb-4",attrs:{"id":"password","name":"password","type":"password","error":invalid && validated || errors && errors.length > 0,"hint":errors[0],"label":_vm._f("capitalize")(_vm.$t('app.labels.password_invite')),"disabled":_vm.$wait.is('creating account'),"autocomplete":"new-password","required":""},model:{value:(_vm.formData.password),callback:function ($$v) {_vm.$set(_vm.formData, "password", $$v)},expression:"formData.password"}})]}}])}),_c('ValidationProvider',{ref:"password-confirmation-provider",attrs:{"name":_vm.$t('app.fields.password'),"rules":"required|min:8|confirmed:password","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
var errors = ref.errors;
return [_c('ctk-input-text',{staticClass:"tw-w-full tw-mb-4",attrs:{"id":"password-confirmation","name":"password-confirmation","type":"password","error":invalid && validated || errors && errors.length > 0,"hint":errors[0],"label":_vm._f("capitalize")(_vm.$t('app.labels.password_confirmation')),"disabled":_vm.$wait.is('creating account'),"autocomplete":"new-password","required":""},model:{value:(_vm.formData.passwordConfirmation),callback:function ($$v) {_vm.$set(_vm.formData, "passwordConfirmation", $$v)},expression:"formData.passwordConfirmation"}})]}}])}),_c('p',{staticClass:"password__form__password-explanation tw-mb-6 tw-italic"},[_vm._v(" "+_vm._s(_vm.$t('app.password.paragraphs.password_constraint'))+" ")]),_c('ValidationProvider',{ref:"tos-provider",attrs:{"name":"tos","rules":"required|length:4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
return [_c('b-form-checkbox',{staticClass:"tw-text-secondary-text tw-mt-4",attrs:{"disabled":_vm.$wait.is('creating account'),"state":invalid && validated ? false : null,"value":"true","required":""},model:{value:(_vm.formData.tos),callback:function ($$v) {_vm.$set(_vm.formData, "tos", $$v)},expression:"formData.tos"}},[_c('div',{staticClass:"tw-inline tw--mt-1"},[_c('i18n',{attrs:{"path":"app.paragraphs.register.tos","tag":"span"},scopedSlots:_vm._u([{key:"tos",fn:function(){return [_c('UiLink',{attrs:{"href":_vm.tos,"target":"_blank","rel":"noopener"},domProps:{"textContent":_vm._s(_vm.$t('app.paragraphs.register.tos_label'))}})]},proxy:true}],null,true)})],1)]),(invalid && validated)?_c('div',{staticClass:"tw-text-red-500 tw-pl-6 tw-mt-1 tw-text-xs",domProps:{"textContent":_vm._s(_vm.$t('validator.required'))}}):_vm._e()]}}])}),_c('ui-button',{staticClass:"tw-w-full tw-mt-8",attrs:{"disabled":_vm.$wait.is('creating account'),"type":"submit","size":"md","variant":"primary"}},[_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.$t('validate')))+" ")])],1)])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }